<script setup>
import {inject, ref, computed, useAttrs} from "vue"
import LayoutContainer from "../layout/LayoutContainer.vue"
import sink from "@/sink/index.js"
import {OneImageCDN} from "@jirizavadil/framework.one/ui"

defineProps({
  cms: Object,
  section: Object,
})

const urlCdnImagesGlobal = inject("FRAMEWORK_ONE_URL_CDN_IMAGES")
</script>

<template>
  <LayoutContainer>
    <!-- title -->
    <div class="">
      <h3 class="whitespace-pre-wrap text-center text-xs font-bold uppercase text-brown-300">
        {{ section.fields.title }}
      </h3>
    </div>

    <!-- items -->
    <div
      class="mx-auto mt-2 grid grid-cols-3 gap-6 md:mt-6 md:flex md:flex-row md:justify-center md:gap-14"
    >
      <div
        class="flex flex-center"
        v-for="mediaFeature in section.fields.mediaFeatures"
        :key="mediaFeature.id"
      >
        <img
          class="h-auto max-h-10 w-full md:max-h-14 md:max-w-32"
          :src="`${urlCdnImagesGlobal}/${mediaFeature.fields.logo.imageId}`"
        />
      </div>
    </div>
  </LayoutContainer>
</template>
