<script setup>
import {ref, onMounted, onUnmounted, watch} from "vue"

const props = defineProps({
  url: {
    type: String,
    required: true,
  },
})

const embedContainer = ref(null)
let instagramScript = null

const loadInstagramScript = () => {
  return new Promise((resolve, reject) => {
    if (window.instgrm) {
      resolve()
      return
    }

    instagramScript = document.createElement("script")
    instagramScript.src = "https://www.instagram.com/embed.js"
    instagramScript.async = true
    instagramScript.defer = true

    instagramScript.onload = () => {
      if (window.instgrm) {
        window.instgrm.Embeds.process()
        resolve()
      } else {
        reject(new Error("Instagram embed script failed to load"))
      }
    }

    instagramScript.onerror = () => {
      reject(new Error("Failed to load Instagram embed script"))
    }

    document.body.appendChild(instagramScript)
  })
}

const embedReel = async () => {
  if (embedContainer.value) {
    // Clear previous content
    embedContainer.value.innerHTML = ""

    // Create blockquote for Instagram embed
    const blockquote = document.createElement("blockquote")
    blockquote.classList.add("instagram-media")
    blockquote.setAttribute("data-instgrm-permalink", props.url)
    blockquote.setAttribute("data-instgrm-version", "14")
    blockquote.style.background = "#FFF"
    blockquote.style.border = "0"
    blockquote.style.borderRadius = "3px"
    blockquote.style.boxShadow = "0 0 1px 0 rgba(0,0,0,0.5),0 1px 10px 0 rgba(0,0,0,0.15)"
    blockquote.style.margin = "1px"
    blockquote.style.maxWidth = "326px" //"540px"
    blockquote.style.minWidth = "326px"
    blockquote.style.padding = "0"
    blockquote.style.width = "99.375%"

    embedContainer.value.appendChild(blockquote)

    try {
      await loadInstagramScript()
    } catch (error) {
      console.error("Error embedding Instagram Reel:", error)
    }
  }
}

// Embed on component mount and when URL changes
onMounted(embedReel)
watch(() => props.url, embedReel)

// Clean up script on unmount
onUnmounted(() => {
  if (instagramScript && document.body.contains(instagramScript)) {
    document.body.removeChild(instagramScript)
  }
})
</script>

<template>
  <div ref="embedContainer"></div>
</template>
